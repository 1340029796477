// import React, { useEffect } from 'react';
// import AOS from 'aos';
// import "aos/dist/aos.css";
// import './index.css';
// import {
//   BrowserRouter as Router,
//   Routes,
//   Route
// } from 'react-router-dom';
// // All pages
// import Home from './pages/Home';
// import Contact from './pages/Contact';
// import DemoProduct from './pages/DemoProduct';

// import {useDocTitle} from './components/CustomHook';
// import ScrollToTop from './components/ScrollToTop';
// import FreeConsultation from './pages/FreeConsultation';
// import VirtualAssistant from './pages/VirtualAssistant';
// import Login from './pages/Login';
// import Register from './pages/Register';
// import Dashboard from './pages/Dashboard/Dashboard';
// import AllContacts from './pages/Dashboard/AllContacts/AllContacts';
// import ProtectedRoute from './components/ProtectedRoute';

// function App() {
//   useEffect(() => {
//     const aos_init = () => {
//       AOS.init({
//         once: true,
//         duration: 1000,
//         easing: 'ease-out-cubic',
//       });
//     }

//     window.addEventListener('load', () => {
//       aos_init();
//     });
//   }, []);

//   useDocTitle("Skyland Web");

//   return (
//     <>
//       <Router>
//         <ScrollToTop>
//           <Routes>
//             <Route path="/" element={<Home />} />
//             <Route path="/contact" element={<Contact />} />
//             <Route path="/consultation" element={<FreeConsultation />} />
//             <Route path="/get-demo" element={<DemoProduct />} /> 
//             <Route path="/skyland-va" element={<VirtualAssistant />} /> 
//             <Route path="/login" element={<Login />} />
//             <Route path="/register" element={<Register/>} />
//             {/* <Route path="/dashboard" element={<Dashboard/>} /> */}
//             {/* Protect the dashboard route */}
//             <Route
//               path="/dashboard"
//               element={
//                 <ProtectedRoute allowedRoles={['Admin']}>
//                   <Dashboard />
//                 </ProtectedRoute>
//               }
//             />
//             <Route path="/all-contacts" element={<AllContacts/>} />

//           </Routes>
//         </ScrollToTop>
//       </Router>
//     </>
//   );
// }


// export default App;


import React, { useEffect } from 'react';
import AOS from 'aos';
import "aos/dist/aos.css";
import './index.css';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom'; // Import useLocation to track routes
import ReactGA from 'react-ga4';

// All pages
import Home from './pages/Home';
import Contact from './pages/Contact';
import DemoProduct from './pages/DemoProduct';
import { useDocTitle } from './components/CustomHook';
import ScrollToTop from './components/ScrollToTop';
import FreeConsultation from './pages/FreeConsultation';
import VirtualAssistant from './pages/VirtualAssistant';
import Login from './pages/Login';
import Register from './pages/Register';
import Dashboard from './pages/Dashboard/Dashboard';
import AllContacts from './pages/Dashboard/AllContacts/AllContacts';
import ProtectedRoute from './components/ProtectedRoute';

function App() {

  // AOS Initialization (this can stay outside Router)
  useEffect(() => {
    const aos_init = () => {
      AOS.init({
        once: true,
        duration: 1000,
        easing: 'ease-out-cubic',
      });
    };

    window.addEventListener('load', () => {
      aos_init();
    });
  }, []);

  // Set document title using custom hook
  useDocTitle("Skyland Web");

  return (
    <>
      <Router>
        <ScrollToTop>
          <RouteTracking /> {/* Google Analytics tracking should be done here */}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/consultation" element={<FreeConsultation />} />
            <Route path="/get-demo" element={<DemoProduct />} />
            <Route path="/skyland-va" element={<VirtualAssistant />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            {/* Protect the dashboard route */}
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute allowedRoles={['Admin']}>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route path="/all-contacts" element={<AllContacts />} />
          </Routes>
        </ScrollToTop>
      </Router>
    </>
  );
}

// Move Google Analytics tracking into a separate component inside Router
function RouteTracking() {
  const location = useLocation(); // Now this has access to Router context

  useEffect(() => {
    // Initialize Google Analytics
    ReactGA.initialize('G-TCKHC9NS9K');  // Your Measurement ID
    ReactGA.send('pageview');  // Sends initial pageview when the app loads

    // Track page views on route changes
    ReactGA.send('pageview', location.pathname + location.search);
  }, [location]);

  return null; // This component doesn't render anything, just tracks routes
}

export default App;
